import React, { useContext } from 'react';
import { BRAND } from '@constants';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { useExperienceContent } from '@hooks/use-server-content';
import { FooterFragment } from '@models/experiencefragments/footer';
import { HeaderFragment } from '@models/experiencefragments/header';
import ServerContext from '@contexts/serverContext';
import { Link } from '@common/index';
import { CcpaBanner } from '../../ccpa-banner/ccpa-banner';
import { LincolnLanguageToggle } from './lincoln-footer-language-toggle';
import './lincoln-na-footer.scss';

export const LincolnNAFooter = () => {
    const [experienceContent] = useExperienceContent<FooterFragment>(
        'common',
        'header-footer',
        'footer',
        BRAND.lincoln.LONG_NAME
    );
    const [experienceHeaderContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header',
        BRAND.lincoln.LONG_NAME
    );
    const { currentRegionCode } = new AppConfigurationService();
    const serverContext = useContext(ServerContext);
    const [country] = [
        serverContext ? serverContext.currentRegionCode : currentRegionCode,
    ];
    return (
        <>
            {experienceContent && experienceHeaderContent && (
                <footer className="lincoln-na-footer">
                    {country === 'us' && <CcpaBanner />}
                    <div className="primary-section pipe-separated-list-container">
                        <ul className="skinny links link">
                            {experienceContent.primarySection.primaryLinks &&
                                experienceContent.primarySection.primaryLinks.map(
                                    (link, index) => {
                                        return (
                                            <li
                                                className="lincoln-content-label"
                                                key={index}
                                            >
                                                <Link
                                                    key={index}
                                                    href={link.url}
                                                    className="sec-link-color"
                                                    target={
                                                        link.targetBlank
                                                            ? '_blank'
                                                            : '_self'
                                                    }
                                                >
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                )}
                        </ul>
                    </div>
                    <a
                        tabIndex={0}
                        href={experienceHeaderContent.logo.url}
                        target={
                            experienceHeaderContent.logo.targetBlank
                                ? '_blank'
                                : ''
                        }
                        className="logo-link"
                        aria-label={experienceHeaderContent.logo.ariaLabel}
                        rel="noopener noreferrer"
                    >
                        <span
                            className="lincoln-logo"
                            style={{
                                backgroundImage: `url('https://www.lincoln.com/cmslibs/etc/designs/brand_ford/brand/skin/lincoln/img/bsr-sprite2x-lincoln.png')`,
                            }}
                        />
                    </a>
                    <div className="tertiary-section links">
                        <p className="copyright"> © 2020 FORD MOTOR COMPANY</p>
                        {experienceContent.tertiarySection.tertiaryLinks &&
                            experienceContent.tertiarySection.tertiaryLinks.map(
                                (link, index) => {
                                    return (
                                        <Link
                                            key={index}
                                            href={link.url}
                                            className={`${
                                                index === 0
                                                    ? 'primary-link'
                                                    : 'link'
                                            }
                                            ${
                                                link?.url?.indexOf(
                                                    'cookieSettings'
                                                ) > 0
                                                    ? 'optanon-toggle-display'
                                                    : ''
                                            }
                                            `}
                                            target={
                                                link.targetBlank
                                                    ? '_blank'
                                                    : '_self'
                                            }
                                        >
                                            {link.title}
                                        </Link>
                                    );
                                }
                            )}
                        <a
                            tabIndex={0}
                            href={experienceHeaderContent.logo.url}
                            target={
                                experienceHeaderContent.logo.targetBlank
                                    ? '_blank'
                                    : ''
                            }
                            className="mobile-logo-link"
                            aria-label={experienceHeaderContent.logo.ariaLabel}
                            rel="noopener noreferrer"
                        >
                            <span
                                className="lincoln-mobile-logo"
                                style={{
                                    backgroundImage: `url('https://www.lincoln.com/cmslibs/etc/designs/brand_ford/brand/skin/lincoln/img/bsr-sprite2x-lincoln.png')`,
                                }}
                            />
                        </a>
                        <LincolnLanguageToggle tabIndex={0} />
                    </div>
                </footer>
            )}
        </>
    );
};
