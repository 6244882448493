import { useContent } from '@hooks/use-server-content';
export interface CcpaBannerProps {
    hide: boolean;
    headerTitle: string;
    content: string;
    ctaLink: string;
    ctaTarget: string;
    ctaAriaLabel: string;
    ctaText: string;
}
export const useCcpaBannerContent = (): CcpaBannerProps | null => {
    const [content, getValueByName] = useContent('common', 'ccpa-banner');
    let contentFragment: any = {};
    content?.elements.forEach((element) => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as CcpaBannerProps;
};
