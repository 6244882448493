import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import AuthenticationService from '../authentication-service/authentication-service';
import { VehicleAttributes } from '../../models/vehicle-attributes';
import { DataLayerService } from '../data-layer-service/data-layer-service';
import { LogService } from '../log-service/log-service';
import ConsumerIdService from '../consumerId-service/consumerId-service';
import ProfileService from '../profile-service/profile-service';
import CheckExperienceType, {
    DeviceType,
} from '../check-device-type/check-experience-type';
import { COOKIE_CONFIGURATION } from '@constants';

export interface ShortcodeProviders {
    ymm?: VehicleAttributes | Promise<VehicleAttributes>;
    nameplate?: string;
    gdprModalButtonPressed?: GdprModalButtonType | Promise<GdprModalButtonType>;
    tileOnClickCtaInfo?: TileOnClickCtaInfo | Promise<TileOnClickCtaInfo>;
    referredUrlPageName?: string;
    dyfComponentName?: string;
    orderType?: string;
    addVehicleStatus?: string;
    languageRegionCode?: string;
    category?: string;
    cvotOrderStatus?: string;
    fprButton?: string;
}
export type TileOnClickCtaInfo = {
    tileName?: string;
    ctaName?: string;
    referredTo?: string;
};

interface ShortcodeParts {
    raw: string;
    name: string;
    parameters: ShortcodeParameter[];
}

interface ShortcodeParameter {
    name: string;
    value: string;
}

export type GdprModalButtonType = 'Accept' | 'Manage';

export class ShortcodeService {
    public constructor(private shortcodeProviders?: ShortcodeProviders) {}

    public async processShortcodes(value: string): Promise<string> {
        const shortcodes: ShortcodeParts[] = this.parseForShortcodes(value);
        return this.injectShortcodeValues(value, shortcodes);
    }

    private parseForShortcodes(value: string): ShortcodeParts[] {
        const shortcodes: ShortcodeParts[] = [];
        try {
            const rawShortcodes = this.getShortcodeMatches(value);
            if (rawShortcodes) {
                rawShortcodes.forEach((rawShortcode: string) => {
                    const shortcodeName = rawShortcode
                        .split(' ')[0]
                        .substr(1)
                        .replace(']', '');
                    const parameterMatches =
                        this.getParameterMatches(rawShortcode);
                    let parameters: ShortcodeParameter[] = [];
                    parameters = parameterMatches.map(
                        (parameterUnparse: string) => {
                            const name: string =
                                this.getParameterName(parameterUnparse);
                            const value =
                                this.getParameterValue(parameterUnparse);
                            return {
                                name: name,
                                value: value,
                            };
                        }
                    );
                    const shortcodeParts: ShortcodeParts = {
                        raw: rawShortcode,
                        name: shortcodeName,
                        parameters: parameters,
                    };
                    shortcodes.push(shortcodeParts);
                });
            }
        } catch (e) {
            LogService.log(
                'Shortcodes',
                'There was an error parsing for shortcodes. Reason: ',
                e.message
            );
        }
        return shortcodes;
    }

    private getShortcodeMatches(value: string): string[] {
        const matches: string[] = [];
        const remaining = value;
        let cursor = 0;
        try {
            while (remaining.indexOf('[', cursor) >= 0) {
                const openingBracketIndex = remaining.indexOf('[', cursor);
                if (remaining[openingBracketIndex - 1] !== '\\') {
                    if (remaining.indexOf(']', cursor) >= 0) {
                        while (remaining.indexOf(']', cursor) >= 0) {
                            const closingBracketIndex = remaining.indexOf(
                                ']',
                                cursor
                            );
                            if (
                                closingBracketIndex >= openingBracketIndex &&
                                remaining[closingBracketIndex - 1] !== '\\'
                            ) {
                                cursor = closingBracketIndex + 1;
                                matches.push(
                                    remaining.substring(
                                        openingBracketIndex,
                                        closingBracketIndex + 1
                                    )
                                );
                                break;
                            } else {
                                cursor = closingBracketIndex + 1;
                            }
                        }
                    } else {
                        throw new Error(
                            `No closing bracket for opening bracket at char ${openingBracketIndex}.`
                        );
                    }
                } else {
                    cursor = openingBracketIndex + 1;
                }
            }
        } catch (e) {
            LogService.log(
                'Shortcodes',
                `Any error occured while trying to parse ${value} for shortcodes. Reason: ${e.message}`
            );
        }
        if (matches) {
            return Array.from(matches);
        }
        return [];
    }

    private getParameterMatches(value: string): string[] {
        let cursor = 0;
        const matches: string[] = [];
        while (value.indexOf(` `, cursor) >= 0) {
            if (value.indexOf(` `, cursor) < value.indexOf(`='`, cursor)) {
                const startIndex = value.indexOf(` `, cursor);
                const middleIndex = value.indexOf(`='`, cursor);
                cursor = middleIndex + 2;
                if (value.indexOf(`'`, cursor) >= 0) {
                    while (value.indexOf(`'`, cursor) >= 0) {
                        if (value[value.indexOf(`'`, cursor) - 1] !== '\\') {
                            const endIndex = value.indexOf(`'`, cursor);
                            cursor = endIndex + 1;
                            matches.push(
                                value.substring(startIndex + 1, endIndex + 1)
                            );
                            break;
                        } else {
                            cursor = value.indexOf(`'`, cursor) + 1;
                        }
                    }
                } else {
                    throw new Error(
                        `No closing quotation mark for parameter starting at char ${startIndex}`
                    );
                }
            } else {
                cursor = value.indexOf(` `, cursor);
            }
        }
        if (matches) {
            return Array.from(matches);
        }
        return [];
    }

    private getParameterName(value: string): string {
        return value.split('=')[0].trim();
    }

    private getParameterValue(value: string): string {
        const cleanedParameterValue = value
            .split('=')[1]
            .replace("\\'", "'")
            .replace('\\[', '[')
            .replace('\\]', ']')
            .replace('\\\\', '\\');
        return cleanedParameterValue.substr(
            1,
            cleanedParameterValue.length - 2
        );
    }

    private async injectShortcodeValues(
        value: string,
        shortcodes: ShortcodeParts[]
    ): Promise<string> {
        let processed: string = value;
        await ShortcodeService.asyncForEach(
            shortcodes,
            async (shortcode: ShortcodeParts) => {
                const shortcodeValue: string = await this.getShortcodeValue(
                    shortcode
                );
                if (shortcodeValue) {
                    processed = processed.replace(
                        shortcode.raw,
                        shortcodeValue
                    );
                }
            }
        );
        return processed;
    }

    private async getShortcodeValue(
        shortcode: ShortcodeParts
    ): Promise<string> {
        let value: string;
        switch (shortcode.name) {
            case 'user-language': {
                const languageMap: any = {};
                languageMap['English'] = 'eng';
                languageMap['Français'] = 'fre';
                languageMap['German'] = 'due';
                languageMap['Czech'] = 'cze';
                languageMap['Italian'] = 'ita';
                languageMap['Dutch'] = 'dut';
                languageMap['Turkish'] = 'tur';
                languageMap['Russian'] = 'rus';
                languageMap['Danish'] = 'dan';
                languageMap['Swedish'] = 'swe';
                languageMap['Polish'] = 'pol';
                languageMap['Greek'] = 'gre';
                languageMap['Rundi'] = 'run';
                languageMap['Norwegian'] = 'nob';
                languageMap['Finnish'] = 'fin';
                languageMap['Hungarian'] = 'hun';
                languageMap['Spanish'] = 'spa';
                languageMap['Portuguese'] = 'por';
                languageMap['Slovak'] = 'slo';

                const currentLanguage = new AppConfigurationService()
                    .currentLanguage;
                if (languageMap[currentLanguage]) {
                    value = languageMap[currentLanguage];
                } else {
                    throw new Error(
                        `Not able to return three letter language code for ${currentLanguage}. Logic for shortcode [user-language] needs to be updated.`
                    );
                }
                break;
            }
            case 'rad-ui-version': {
                const width = document.body.clientWidth;
                if (width > 992) {
                    value = 'ui:rad:pc';
                } else if (width > 768) {
                    value = 'ui:rad:tablet';
                } else {
                    value = 'ui:rad:mobile';
                }
                break;
            }
            case 'selected-vehicle-year': {
                if (this.shortcodeProviders?.ymm) {
                    const ymm: VehicleAttributes = await Promise.resolve(
                        this.shortcodeProviders.ymm
                    );
                    value = `${ymm.year}`;
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'nameplate': {
                if (this.shortcodeProviders?.nameplate) {
                    value = this.shortcodeProviders.nameplate;
                } else {
                    throw new Error(`Nameplate unavailable for chosen vehicle`);
                }
                break;
            }
            case 'selected-vehicle-make': {
                if (this.shortcodeProviders?.ymm) {
                    const ymm: VehicleAttributes = await Promise.resolve(
                        this.shortcodeProviders.ymm
                    );
                    value = `${ymm.make}`.toLowerCase();
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'selected-vehicle-model': {
                if (this.shortcodeProviders?.ymm) {
                    const ymm: VehicleAttributes = await Promise.resolve(
                        this.shortcodeProviders.ymm
                    );
                    value = `${ymm.model}`.toLowerCase();
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'selected-vehicle-delivery-status': {
                if (this.shortcodeProviders?.ymm) {
                    const ymm: VehicleAttributes = await Promise.resolve(
                        this.shortcodeProviders.ymm
                    );
                    value = `${ymm.ownerState === 0 ? 'pre' : 'post'}`;
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'login-status': {
                const authenticationService = new AuthenticationService();
                value = (await authenticationService.onIsAuthenticated())
                    ? 'logged in'
                    : 'logged out';
                break;
            }
            case 'registered-status': {
                const authenticationService = new AuthenticationService();
                if (await authenticationService.onIsAuthenticated()) {
                    value = 'registered';
                } else {
                    throw new Error(
                        'Cannot return registered status for unauthenticated user.'
                    );
                }
                break;
            }
            case 'country-code': {
                const currentCountryCode = new AppConfigurationService()
                    .currentCountryCode;
                value = currentCountryCode;
                break;
            }
            case 'selected-country': {
                value = this.shortcodeProviders.languageRegionCode;
                break;
            }
            case 'order-type': {
                value = this.shortcodeProviders.orderType;
                break;
            }
            case 'add-vehicle-status': {
                value = this.shortcodeProviders.addVehicleStatus;
                break;
            }
            case 'customer-id': {
                const appConfig = new AppConfigurationService();
                const service = new ProfileService();
                const response: any = await service.request();
                if (
                    response?.profile?.country ===
                    appConfig.get3LetterCountryCode().toUpperCase()
                ) {
                    value = await this.getConsumerIdFromService();
                }

                break;
            }
            case 'gdpr-opt-status': {
                const retrievedCookieConfig =
                    localStorage.getItem(COOKIE_CONFIGURATION);
                if (retrievedCookieConfig) {
                    const cookieSettings = JSON.parse(
                        retrievedCookieConfig
                    ).value;
                    const analyticSettings: string[] = [];
                    for (const key in cookieSettings) {
                        const value = cookieSettings[key];
                        const analyticValue = value ? 'yes' : 'no';
                        analyticSettings.push(`${key}:${analyticValue}`);
                    }
                    value = analyticSettings.join('|');
                } else if (shortcode.parameters.length > 0) {
                    value = shortcode.parameters
                        .map((p) => `${p.name.replace('-', ' ')}:${p.value}`)
                        .join('|');
                } else {
                    throw new Error(
                        'Cannot return opt in status. GDPR user preferences not found.'
                    );
                }
                break;
            }
            case 'from-datalayer': {
                const propertyDescriptorParameter = shortcode.parameters.filter(
                    (parameter) => parameter.name === 'property-descriptor'
                )[0];
                if (propertyDescriptorParameter) {
                    const propertyValue = DataLayerService.getProperty(
                        propertyDescriptorParameter.value,
                        (window as any).digitaldata
                    );
                    if (propertyValue) {
                        value = propertyValue;
                    } else {
                        throw new Error(
                            `Property "${propertyDescriptorParameter.value}" is not populate in digitaldata.`
                        );
                    }
                } else {
                    throw new Error(
                        `Parameter "property-descriptor" needs to be set for this shortcode to populate.`
                    );
                }
                break;
            }
            case 'gdpr-modal-button-pressed': {
                if (this.shortcodeProviders?.gdprModalButtonPressed) {
                    const gdprModalButtonPressed = await Promise.resolve(
                        this.shortcodeProviders?.gdprModalButtonPressed
                    );
                    const acceptParameter = shortcode.parameters.filter(
                        (parameter) => parameter.name === 'accept'
                    )[0];
                    const manageParameter = shortcode.parameters.filter(
                        (parameter) => parameter.name === 'manage'
                    )[0];
                    if (acceptParameter && manageParameter) {
                        if (gdprModalButtonPressed === 'Accept') {
                            value = acceptParameter.value;
                        } else {
                            value = manageParameter.value;
                        }
                    } else {
                        throw new Error(
                            `Both "accept" and "manage" are required properties, one of them is missing.`
                        );
                    }
                } else {
                    throw new Error(
                        `Tried to populate shortcode ${shortcode}, required data was not available. Please make sure you are using the shortcode in the correct context.`
                    );
                }
                break;
            }
            case 'tile-name': {
                if (this.shortcodeProviders?.tileOnClickCtaInfo) {
                    const tileOnClickCtaInfo: TileOnClickCtaInfo = await this
                        .shortcodeProviders.tileOnClickCtaInfo;
                    value = `${tileOnClickCtaInfo.tileName}`;
                } else {
                    throw new Error(`Could not populate Tile Name`);
                }
                break;
            }
            case 'cta-name': {
                if (this.shortcodeProviders?.tileOnClickCtaInfo) {
                    const tileOnClickCtaInfo: TileOnClickCtaInfo = await this
                        .shortcodeProviders.tileOnClickCtaInfo;
                    value = `${tileOnClickCtaInfo.ctaName}`;
                } else {
                    throw new Error(`Could not populate CTA Name`);
                }
                break;
            }
            case 'component-name': {
                if (this.shortcodeProviders?.dyfComponentName) {
                    value = this.shortcodeProviders?.dyfComponentName;
                } else {
                    throw new Error(`Could not populate component Name`);
                }
                break;
            }
            case 'referred-url-page-name': {
                if (this.shortcodeProviders?.referredUrlPageName) {
                    value = this.shortcodeProviders?.referredUrlPageName;
                } else {
                    throw new Error(
                        `Could not populate referred url page name`
                    );
                }
                break;
            }
            case 'experience-type': {
                const expType = CheckExperienceType();
                if (expType === DeviceType.MOBILE) {
                    value = 'mobile';
                } else if (expType === DeviceType.TABLET) {
                    value = 'tablet';
                } else {
                    value = 'desktop';
                }
                break;
            }
            case 'env-domain': {
                const { appConfigurations, currentLanguageRegionCode, brand } =
                    new AppConfigurationService();
                const currentAppConfig = appConfigurations.filter(
                    (appConfig) =>
                        brand == appConfig.brand &&
                        appConfig.languageRegionCode ==
                            currentLanguageRegionCode
                );
                if (currentAppConfig.length != 0) {
                    value =
                        currentAppConfig[0].domain != ''
                            ? 'https://' + currentAppConfig[0].domain
                            : '';
                } else {
                    value = '';
                }
                break;
            }
            case 'order-status': {
                if (this.shortcodeProviders?.cvotOrderStatus) {
                    value = this.shortcodeProviders?.cvotOrderStatus;
                } else {
                    throw new Error(`Could not populate order status`);
                }
                break;
            }
            case 'fpr-button': {
                if (this.shortcodeProviders?.fprButton) {
                    value = this.shortcodeProviders?.fprButton;
                } else {
                    throw new Error(
                        `Could not populate ford pass rewards button`
                    );
                }
                break;
            }
            case 'category': {
                if (this.shortcodeProviders?.category) {
                    value = this.shortcodeProviders?.category;
                } else {
                    throw new Error(`Could not populate category`);
                }
                break;
            }
            default: {
                throw new Error(
                    `Shortcode ${shortcode} not recognized. A value cannot be provided.`
                );
            }
        }
        return value;
    }

    private async getConsumerIdFromService() {
        const consumerId = await new ConsumerIdService().getConsumerId();
        if (consumerId) {
            return consumerId.consumer.id;
        } else {
            throw new Error('There was an issue getting consumer ID');
        }
    }

    private static async asyncForEach<T>(array: T[], callback: Function) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
}
