import React from 'react';
import './status-chip.scss';

interface VehicleNotificationAlerts {
    notificationCount: number;
    servicesNeededText?: string;
}

const StatusChip = (props: VehicleNotificationAlerts) => {
    return (
        <div
            className={`status-chip-alerts ${
                props.servicesNeededText ? 'services-needed' : ''
            }`}
        >
            {props.notificationCount}
            {props.servicesNeededText && (
                <span className="services-needed__text">
                    &nbsp;{props.servicesNeededText || ''}
                </span>
            )}
        </div>
    );
};

export default StatusChip;
