import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import { PreferredDealerContent } from '@views/preferred-dealer-view/hooks/use-preferred-dealer-content';
import {
    RadioButtonGroup,
    RadioButtonOption,
} from '@common/form-fields/form-fields';
import IndividualDealer from '@views/preferred-dealer-view/components/individual-dealer/individual-dealer';
import {
    Dealer,
    EUDealer,
    PreferredDealerRequest,
    PreferredDealerResponse,
    VehicleDataPreferredDealer,
} from '@models/preferred-dealer';
import HttpService from '@services/http-service/http-service';
import PreferredDealerService from '@services/preferred-dealer-service/preferred-dealer-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import {
    EU_POLYGON,
    EU_PREFERRED_DEALER_ID_PREFIXES,
    KEYBOARD_KEYS,
} from '@constants';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { PreferredDealerItem } from '@views/preferred-dealer-view/preferred-dealer-view';
import { scriptService } from '@services/script-service/script-service';
import { BingMaps } from '@sections/maps/bing-maps';
import { CacheService } from '@services/cache-service/cache-service';
import { useAnalytics } from '@hooks/use-analytics';
import GoogleMapService from '@/services/google-map-service/google-map-service';
import { EuMap } from '@/components/sections/maps/eu-map';
import DealerInfoTile from './dealer-info-tile';
import useMarketDealerInfoService from '@views/preferred-dealer-view/hooks/use-market-dealerInfo-service';
import { useLocation } from 'react-router-dom';
import {
    AllOsbDealersResponse,
    DealerProfileInfo,
} from '@models/eu-map-models/eu-map-dealer-info';
import EuBingService from '@services/eu-bing-service/eu-bing-service';
import EuMobileCarousel from '@views/preferred-dealer-view/components/eu-mobile-carousel/eu-mobile-carousel';
import PrimaryButton from '@common/primary-button/primary-button';

interface PreferredDealerComponentProps {
    preferredDealerContent: PreferredDealerContent;
    vin: string;
    selectedPreferredDealer: string;
    selectedModel: string;
    updatePreferredDealer: (newDealer: PreferredDealerItem) => void;
    instantPreferredDealer: string;
    setInstantPreferredDealer: Dispatch<SetStateAction<string>>;
    defaultDealerLocation: string;
    isMobile: boolean;
}

const PreferredDealerComponent = (props: PreferredDealerComponentProps) => {
    const { preferredDealerContent }: PreferredDealerComponentProps = props;
    const dealerResultsListRef = useRef<HTMLDivElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const location = useLocation();
    const httpService = HttpService;
    const [fireAnalytics] = useAnalytics();
    const preferredDealerService = new PreferredDealerService(httpService);
    const appConfig = new AppConfigurationService();
    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';
    const scrollUtil = new ScrollUtil();
    const convertedBrand: string =
        appConfig.brand === 'ford' ? 'Ford' : 'Lincoln';
    let requestObject: PreferredDealerRequest = { make: convertedBrand };
    const { fetchMarketDealerInfo } = useMarketDealerInfoService();
    const [completeDealerResults, setCompleteDealerResults] =
        useState<Dealer[]>(null);
    const [dealersByDistance, setDealersByDistance] =
        useState<EUDealer[]>(null);
    const [dealerResults, setDealerResults] = useState<Dealer[]>(null);
    const [dealerResultsEu, setDealerResultsEu] = useState<EUDealer[]>(null);
    const [dealerNameSearchResultsEu, setDealerNameSearchResultsEu] =
        useState<DealerProfileInfo[]>(null);
    const [filteredForSelectedDealer, setFilteredForSelectedDealer] =
        useState<Dealer[]>(null);
    const [filteredForSelectedDealerEu, setFilteredForSelectedDealerEu] =
        useState<EUDealer[]>(null);
    const [activeDealer, setActiveDealer] = useState<EUDealer>(undefined);
    const [bingMapsScriptLoaded, setBingMapsScriptLoaded] =
        useState<boolean>(false);
    const [googleMapsScriptLoaded, setGoogleMapsScriptLoaded] =
        useState<boolean>(false);
    const [mapExists, setMapsExists] = useState<boolean>(false);
    const [showErrorMessageNoResults, setShowErrorMessageNoResults] =
        useState<boolean>(false);
    const [
        showErrorMessageSearchUnavailable,
        setShowErrorMessageSearchUnavailable,
    ] = useState<boolean>(false);
    const [
        showErrorMessageInvalidPostalCode,
        setShowErrorMessageInvalidPostalCode,
    ] = useState<boolean>(false);
    const [
        showErrorMessageInvalidCityState,
        setShowErrorMessageInvalidCityState,
    ] = useState<boolean>(false);
    const [
        showErrorMessageInvalidUnknownLocationSearch,
        setShowErrorMessageInvalidUnknownLocationSearch,
    ] = useState<boolean>(false);
    const isErrorVisible: boolean =
        showErrorMessageSearchUnavailable ||
        showErrorMessageNoResults ||
        showErrorMessageInvalidPostalCode ||
        showErrorMessageInvalidCityState ||
        showErrorMessageInvalidUnknownLocationSearch;
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [showViewMoreButton, setShowViewMoreButton] = useState<boolean>(true);
    const [searchValue, setSearchValue] = useState<string>('');
    const [readyToAutoSearch, setReadyToAutoSearch] = useState<boolean>(false);
    const clearButtonIcon = './icons/preferred-dealer-input-close-icon.svg';

    const radioButtonOptions: RadioButtonOption[] = [
        {
            displayName: preferredDealerContent.locationText,
            name: 'location',
            id: 'search-by-location',
            value: 'location',
        },
        {
            displayName: preferredDealerContent.dealerNameText,
            name: 'dealer-name',
            id: 'search-by-dealer-name',
            value: 'dealer-name',
        },
    ];
    const [searchMode, setSearchMode] = useState<string>(
        radioButtonOptions[0].value
    );
    const defaultCheckedOption: RadioButtonOption = radioButtonOptions[0].value;
    const handleChange = (value): void => {
        if (value === 'location') {
            fireAnalytics('locationSearchPreferredDealerOnclickEvent');
        } else if (value === 'dealer-name') {
            fireAnalytics('dealerNameSearchPreferredDealerOnclickEvent');
        }

        setSearchMode(value);
    };
    const handleKeyPressAndCloseMobileKeyboard = (e): void => {
        if (e.key === KEYBOARD_KEYS.ENTER) {
            inputRef.current?.blur();
        }
    };
    const clearErrors = (): void => {
        setShowErrorMessageInvalidPostalCode(false);
        setShowErrorMessageNoResults(false);
        setShowErrorMessageSearchUnavailable(false);
        setShowErrorMessageInvalidCityState(false);
        setShowErrorMessageInvalidUnknownLocationSearch(false);
    };

    const computeDealerRequest = () => {
        const hasNumbers: boolean = /\d/.test(searchValue);
        const hasComma: boolean = searchValue.indexOf(',') > -1;
        const isCanada: boolean = appConfig.get2LetterCountryCode() === 'ca';

        if (isCanada) {
            requestObject = {
                ...requestObject,
                isCanada: true,
            };
        }

        if (searchMode === 'location') {
            const splitValue: string[] = searchValue.split(',');

            if (hasNumbers) {
                if (isCanada) {
                    const hasSpace: boolean = searchValue.indexOf(' ') > -1;
                    const firstHalfNoSpace: string = searchValue
                        .substring(0, 3)
                        .toUpperCase();
                    const secondHalfNoSpace: string = searchValue
                        .substring(3)
                        .toUpperCase();
                    const firstHalfWithSpace: string = searchValue
                        .split(' ')[0]
                        ?.toUpperCase();
                    const secondHalfWithSpace: string = searchValue
                        .split(' ')[1]
                        ?.toUpperCase();

                    requestObject = {
                        ...requestObject,
                        postalCode: hasSpace
                            ? `${firstHalfWithSpace}${secondHalfWithSpace}`
                            : `${firstHalfNoSpace}${secondHalfNoSpace}`,
                        isCanada: true,
                    };
                } else {
                    requestObject = {
                        ...requestObject,
                        postalCode: searchValue,
                    };
                }
            } else if (hasComma) {
                if (isCanada) {
                    requestObject = {
                        ...requestObject,
                        city: splitValue[0].trim(),
                        province: splitValue[1].trim().toUpperCase(),
                        isCanada: true,
                    };
                } else {
                    requestObject = {
                        ...requestObject,
                        city: splitValue[0].trim(),
                        state: splitValue[1].trim().toUpperCase(),
                    };
                }
            }
        }
        if (searchMode === 'dealer-name') {
            requestObject = {
                ...requestObject,
                dealerName: searchValue,
            };
        }
    };
    const chooseCorrectError = (message: string): void => {
        if (
            searchMode === 'location' &&
            (message.includes('postalCode must be in') ||
                message.includes('Invalid Postal Code'))
        ) {
            setShowErrorMessageInvalidPostalCode(true);
        } else if (
            message.includes('State is not a valid state') ||
            message.includes('Invalid Canada Province')
        ) {
            setShowErrorMessageInvalidCityState(true);
        } else if (message.includes('At least one of postalCode')) {
            setShowErrorMessageInvalidUnknownLocationSearch(true);
        } else {
            setShowErrorMessageSearchUnavailable(true);
        }
        setDealerResults(null);
    };

    const fetchCompleteDealerList =
        async (): Promise<AllOsbDealersResponse> => {
            const deepLinkParams = new URLSearchParams(location.search);

            const vehicleData: VehicleDataPreferredDealer = {
                mileage: '0',
                modelName: 'F-150',
                buildYear: '2022',
            };

            return await fetchMarketDealerInfo(vehicleData, deepLinkParams);
        };

    const getDealerResults = async (): Promise<void> => {
        setIsSearching(true);
        clearErrors();
        !isEu && computeDealerRequest();

        if (isEu && googleMapsScriptLoaded) {
            searchMode === 'location' &&
                GoogleMapService.getGeocodeLocation(
                    appConfig.currentCountryCode,
                    searchValue,
                    (response: any) => {
                        if (!response.length) {
                            setShowErrorMessageNoResults(true);
                            setFilteredForSelectedDealerEu(null);
                            setIsSearching(false);
                        }

                        GoogleMapService.searchDealersByDistance(
                            { lat: response[0]?.lat, lng: response[0]?.lng },
                            searchMode === 'location' ? 50 : null,
                            25,
                            (dealers: EUDealer[]) => {
                                let dealersWithoutDuplicates: EUDealer[];

                                if (dealers.length) {
                                    dealersWithoutDuplicates = dealers.filter(
                                        (value, i, self) => {
                                            return (
                                                i ===
                                                self.findIndex(
                                                    (t) =>
                                                        t.location.lat ===
                                                            value.location
                                                                .lat &&
                                                        t.location.lng ===
                                                            value.location.lng
                                                )
                                            );
                                        }
                                    );
                                }

                                setDealersByDistance(dealersWithoutDuplicates);
                                setIsSearching(false);
                            },
                            {
                                CountryCode: appConfig
                                    .get3LetterCountryCode()
                                    .toLowerCase(),
                            },
                            EU_POLYGON
                        );
                    }
                );

            if (searchMode === 'dealer-name') {
                let filtered: DealerProfileInfo[];

                await fetchCompleteDealerList()
                    .then((response) => {
                        filtered = response.dealers?.filter((dealer) =>
                            dealer.dealerName
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        );
                    })
                    .finally(() => {
                        setDealerNameSearchResultsEu(filtered);

                        if (!filtered.length) {
                            setShowErrorMessageNoResults(true);
                            setFilteredForSelectedDealerEu(null);
                            setIsSearching(false);
                        }
                    });
            }
        } else {
            await preferredDealerService
                .getPreferredDealerResults(requestObject)
                .then((response: PreferredDealerResponse) => {
                    if (response?.dealer?.length) {
                        const selectedComesFirst: Dealer =
                            response.dealer.filter(
                                (dealer: Dealer) =>
                                    dealer.cupid ===
                                    props.instantPreferredDealer
                            )[0];
                        if (selectedComesFirst) {
                            setCompleteDealerResults([
                                selectedComesFirst,
                                ...response.dealer.filter(
                                    (remainingDealer: Dealer) =>
                                        remainingDealer.cupid !==
                                        props.instantPreferredDealer
                                ),
                            ]);
                        } else {
                            setCompleteDealerResults(response.dealer);
                        }

                        setShowViewMoreButton(true);
                    } else if (response.message) {
                        chooseCorrectError(response.message);
                    } else if (!response?.dealer) {
                        setShowErrorMessageNoResults(true);
                        setDealerResults(null);
                    }
                })
                .finally(() => setIsSearching(false));
            new CacheService().evictPreferredDealerCache();
        }
    };

    useEffect(() => {
        if (dealerNameSearchResultsEu) {
            const promises = dealerNameSearchResultsEu.map((dealer) =>
                EuBingService.searchDealersByProperties(25, null, {
                    DealerID:
                        EU_PREFERRED_DEALER_ID_PREFIXES.find(
                            (country) =>
                                country.country === appConfig.currentCountryCode
                        ).prefix + dealer.dealerCode,
                })
            );

            Promise.all(promises)
                .then((responses) => {
                    const keepers = responses
                        .filter(
                            (response, i) =>
                                response.length &&
                                response[0].DealerID.substring(2).includes(
                                    dealerNameSearchResultsEu[i].dealerCode
                                )
                        )
                        .map((response) => ({
                            ...response[0],
                            location: {
                                lat: response[0].Latitude,
                                lng: response[0].Longitude,
                            },
                        }));

                    if (keepers.length) {
                        setDealersByDistance(keepers);
                        if (keepers.length > 5) setShowViewMoreButton(true);
                    } else {
                        setShowErrorMessageNoResults(true);
                        setFilteredForSelectedDealerEu(null);
                    }
                })
                .finally(() => {
                    setIsSearching(false);
                });
        }
    }, [dealerNameSearchResultsEu]);

    const expandResultsByFive = (
        filteredDealerResults,
        fullDealerResults
    ): void => {
        fireAnalytics('viewMorePreferredDealerOnclickEvent');

        if (
            filteredDealerResults.length === 25 ||
            filteredDealerResults.length === fullDealerResults.length
        ) {
            setShowViewMoreButton(false);
            return;
        }

        isEu
            ? setDealerResultsEu(
                  fullDealerResults.slice(0, filteredDealerResults.length + 5)
              )
            : setDealerResults(
                  fullDealerResults.slice(0, filteredDealerResults.length + 5)
              );
    };

    const scrollToTopOfDealerResultsList = (): void => {
        scrollUtil.scrollPageToTop();
        dealerResultsListRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isEu && dealersByDistance?.length) {
            setDealerResultsEu(dealersByDistance.slice(0, 5));
        } else if (completeDealerResults) {
            setDealerResults(completeDealerResults.slice(0, 5));
        }
    }, [completeDealerResults, dealersByDistance]);

    useEffect(() => {
        if (isEu && dealerResultsEu) {
            const selectedComesFirst: EUDealer = dealerResultsEu.find(
                (dealer: EUDealer) =>
                    dealer.DealerID.substring(2) ===
                    props.instantPreferredDealer
            );

            if (selectedComesFirst) {
                setFilteredForSelectedDealerEu([
                    selectedComesFirst,
                    ...dealerResultsEu.filter(
                        (dealer) =>
                            dealer.DealerID.substring(2) !==
                            props.instantPreferredDealer
                    ),
                ]);
            } else {
                setFilteredForSelectedDealerEu(dealerResultsEu);
            }
            setIsSearching(false);

            if (dealerResultsEu.length === dealersByDistance.length) {
                setShowViewMoreButton(false);
            }
        } else if (dealerResults) {
            const selectedComesFirst: Dealer = completeDealerResults.find(
                (dealer: Dealer) =>
                    dealer.cupid === props.instantPreferredDealer
            );

            if (selectedComesFirst) {
                setFilteredForSelectedDealer([
                    selectedComesFirst,
                    ...dealerResults.filter(
                        (dealer) =>
                            dealer.cupid !== props.instantPreferredDealer
                    ),
                ]);
            } else {
                setFilteredForSelectedDealer(dealerResults);
            }

            if (
                !isEu &&
                dealerResults &&
                dealerResults.length === completeDealerResults.length
            ) {
                setShowViewMoreButton(false);
            }
        }
    }, [
        dealerResults,
        dealerResultsEu,
        dealersByDistance,
        props.instantPreferredDealer,
    ]);

    useEffect(() => {
        if (props.defaultDealerLocation && searchMode === 'location') {
            setSearchValue(props.defaultDealerLocation);
        }

        setTimeout(() => {
            setReadyToAutoSearch(true);
        }, 1000);

        scrollUtil.scrollPageToTop();
    }, [props.defaultDealerLocation]);

    useEffect(() => {
        if (filteredForSelectedDealerEu) {
            setActiveDealer(filteredForSelectedDealerEu[0]);
        }
    }, [filteredForSelectedDealerEu]);

    useEffect(() => {
        fireAnalytics('ownerPreferredDealerSearch');

        !isEu &&
            scriptService.loadBingMapsScript(() => {
                setBingMapsScriptLoaded(true);
            });
        isEu &&
            scriptService.loadGoogleMapsScript(() => {
                setGoogleMapsScriptLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (readyToAutoSearch && searchValue) {
            (async () => await getDealerResults())();
        }
    }, [readyToAutoSearch]);

    return (
        <>
            <div
                className="preferred-dealer__component"
                data-testid="preferred-dealer-component"
            >
                <div className="content-above-map">
                    <div
                        className="preferred-dealer__component--header"
                        data-testid="preferred-dealer-header-text"
                    >
                        <h1>{preferredDealerContent.headerText}</h1>
                        <p>{props.selectedModel}</p>
                        {props.preferredDealerContent.vinLabelText && (
                            <p>
                                {props.preferredDealerContent.vinLabelText}:{' '}
                                {props.vin}
                            </p>
                        )}
                    </div>

                    <div
                        className="preferred-dealer__component--radio-button-container"
                        data-testid="preferred-dealer-radio-buttons"
                    >
                        <p className="radio-button-group__label">
                            {preferredDealerContent.searchByText}:{' '}
                        </p>

                        <RadioButtonGroup
                            RadioButtonOptions={radioButtonOptions}
                            handleChange={handleChange}
                            defaultCheckedOption={defaultCheckedOption}
                            isHorizontal
                        />
                    </div>
                    <div className="preferred-dealer__component--search-box">
                        <label htmlFor="preferred-dealer-search-input">
                            {searchMode === 'location'
                                ? preferredDealerContent.searchFieldByLocationPlaceholderText
                                : preferredDealerContent.searchFieldByDealerNamePlaceholderText}
                        </label>

                        <form action="" onSubmit={(e) => e.preventDefault()}>
                            <input
                                ref={inputRef}
                                id="preferred-dealer-search-input"
                                type="text"
                                data-testid="preferred-dealer-search-input"
                                value={searchValue}
                                autoComplete="off"
                                aria-autocomplete="none"
                                name="preferred-dealer-search-input"
                                placeholder={
                                    searchMode === 'location'
                                        ? preferredDealerContent.searchFieldByLocationPlaceholderText
                                        : preferredDealerContent.searchFieldByDealerNamePlaceholderText
                                }
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyDown={(e) => {
                                    handleKeyPressAndCloseMobileKeyboard(e);

                                    if (e.key === KEYBOARD_KEYS.ENTER) {
                                        (async () =>
                                            await getDealerResults())();
                                    }
                                }}
                                spellCheck={false}
                            />
                        </form>

                        <button
                            className="clear-button"
                            data-testid="preferred-dealer-clear-button"
                            aria-label="Clear search bar"
                            onClick={() => {
                                setSearchValue('');
                                isEu && setFilteredForSelectedDealerEu(null);
                                !isEu && setDealerResults(null);
                                clearErrors();
                            }}
                        >
                            <img src={clearButtonIcon} alt="" />
                        </button>

                        {props.isMobile &&
                            preferredDealerContent.searchSubmitButtonLabelText && (
                                <PrimaryButton
                                    ariaLabel={
                                        preferredDealerContent.searchSubmitButtonAriaLabel
                                    }
                                    className="preferred-dealer-search-submit-button"
                                    role="button"
                                    testId="preferred-dealer-search-submit-button"
                                    onClick={async () =>
                                        await getDealerResults()
                                    }
                                    disabled={!searchValue}
                                >
                                    {
                                        preferredDealerContent.searchSubmitButtonLabelText
                                    }
                                </PrimaryButton>
                            )}

                        {isErrorVisible && (
                            <>
                                {showErrorMessageNoResults && (
                                    <p
                                        className="preferred-dealer__error-message"
                                        data-testid="preferred-dealer-error-no-results"
                                    >
                                        {
                                            preferredDealerContent.errorMessageNoResults
                                        }
                                    </p>
                                )}
                                {showErrorMessageSearchUnavailable && (
                                    <p
                                        className="preferred-dealer__error-message"
                                        data-testid="preferred-dealer-error-search-unavailable"
                                    >
                                        {
                                            preferredDealerContent.errorMessageSearchUnavailable
                                        }
                                    </p>
                                )}
                                {showErrorMessageInvalidPostalCode && (
                                    <p
                                        className="preferred-dealer__error-message"
                                        data-testid="preferred-dealer-error-invalid-postal-code"
                                    >
                                        {
                                            preferredDealerContent.errorMessageInvalidPostalCode
                                        }
                                    </p>
                                )}
                                {showErrorMessageInvalidCityState && (
                                    <p
                                        className="preferred-dealer__error-message"
                                        data-testid="preferred-dealer-error-invalid-city-state"
                                    >
                                        {
                                            preferredDealerContent.errorMessageInvalidCityState
                                        }
                                    </p>
                                )}
                                {showErrorMessageInvalidUnknownLocationSearch && (
                                    <p
                                        className="preferred-dealer__error-message"
                                        data-testid="preferred-dealer-error-invalid-unknown-location-search"
                                    >
                                        {
                                            preferredDealerContent.errorMessageInvalidUnknownLocationSearch
                                        }
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </div>

                {!isEu &&
                dealerResults &&
                filteredForSelectedDealer &&
                !isSearching ? (
                    <div className="preferred-dealer__component--search-and-map">
                        <div
                            ref={dealerResultsListRef}
                            className={`preferred-dealer__component--search-results ${
                                filteredForSelectedDealer.length < 3
                                    ? 'remove-scroll'
                                    : ''
                            }`}
                        >
                            {filteredForSelectedDealer.map(
                                (dealer: Dealer, index: number) => {
                                    return (
                                        <div key={`dealer-result-${index + 1}`}>
                                            <IndividualDealer
                                                id={index + 1}
                                                brand={appConfig.brand}
                                                preferredDealerContent={
                                                    preferredDealerContent
                                                }
                                                dealerInfo={dealer}
                                                selectedModel={
                                                    props.selectedModel
                                                }
                                                vin={props.vin}
                                                isPreferredDealer={
                                                    props.instantPreferredDealer ===
                                                    dealer.cupid
                                                }
                                                updatePreferredDealer={
                                                    props.updatePreferredDealer
                                                }
                                                instantPreferredDealer={
                                                    props.instantPreferredDealer
                                                }
                                                setInstantPreferredDealer={
                                                    props.setInstantPreferredDealer
                                                }
                                                scrollToTopOfDealerResultsList={
                                                    scrollToTopOfDealerResultsList
                                                }
                                            />
                                        </div>
                                    );
                                }
                            )}
                            {showViewMoreButton && (
                                <div className="view-more-button__box">
                                    <button
                                        className="view-more-button"
                                        aria-label={
                                            preferredDealerContent.viewMoreButtonAriaLabel
                                        }
                                        onClick={() =>
                                            expandResultsByFive(
                                                dealerResults,
                                                completeDealerResults
                                            )
                                        }
                                    >
                                        <span>
                                            {
                                                preferredDealerContent.viewMoreButtonLabelText
                                            }
                                        </span>
                                        {appConfig.brand === 'lincoln' && (
                                            <div className="lincoln-underline-button-overlay"></div>
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="map-container">
                            <BingMaps
                                dealerResults={filteredForSelectedDealer}
                                setDealerResults={setDealerResults}
                                scriptLoaded={bingMapsScriptLoaded}
                                mapExists={mapExists}
                                setMapExists={setMapsExists}
                            />
                        </div>
                    </div>
                ) : null}
                {filteredForSelectedDealerEu?.length &&
                dealersByDistance &&
                dealerResultsEu &&
                !isSearching ? (
                    <div className="preferred-dealer__component--eu-search-and-map">
                        <div>
                            <div className="eu-map-container">
                                <EuMap
                                    dealers={filteredForSelectedDealerEu}
                                    selectedDealer={activeDealer}
                                    onDealerClick={setActiveDealer}
                                    leftShiftDegree={props.isMobile ? 0 : -0.15}
                                    newUIDesign
                                ></EuMap>
                            </div>
                            <div
                                className={`search-list-container`}
                                ref={divRef}
                            >
                                {!props.isMobile &&
                                    filteredForSelectedDealerEu.map(
                                        (dealer: EUDealer, index) => (
                                            <div
                                                key={dealer.DealerID}
                                                data-dealercode={
                                                    dealer.DealerID
                                                }
                                                className="dealer-info-box-wrapper"
                                            >
                                                <DealerInfoTile
                                                    dealer={dealer}
                                                    index={index}
                                                    brand={appConfig.brand}
                                                    preferredDealerContent={
                                                        preferredDealerContent
                                                    }
                                                    isPreferredDealer={
                                                        props.instantPreferredDealer ===
                                                        dealer.DealerID.substring(
                                                            2
                                                        )
                                                    }
                                                    selectedModel={
                                                        props.selectedModel
                                                    }
                                                    instantPreferredDealer={
                                                        props.instantPreferredDealer
                                                    }
                                                    setInstantPreferredDealer={
                                                        props.setInstantPreferredDealer
                                                    }
                                                    updatePreferredDealer={
                                                        props.updatePreferredDealer
                                                    }
                                                    vin={props.vin}
                                                    scrollToTopOfDealerResultsList={
                                                        scrollToTopOfDealerResultsList
                                                    }
                                                />
                                            </div>
                                        )
                                    )}
                                {props.isMobile && (
                                    <EuMobileCarousel
                                        dealers={filteredForSelectedDealerEu}
                                        activeDealer={activeDealer}
                                        setActiveDealer={setActiveDealer}
                                        cards={filteredForSelectedDealerEu.map(
                                            (dealer: EUDealer, index) => (
                                                <div
                                                    key={dealer.DealerID}
                                                    data-dealercode={
                                                        dealer.DealerID
                                                    }
                                                    className="dealer-info-box-wrapper"
                                                >
                                                    <DealerInfoTile
                                                        dealer={dealer}
                                                        index={index}
                                                        brand={appConfig.brand}
                                                        preferredDealerContent={
                                                            preferredDealerContent
                                                        }
                                                        isPreferredDealer={
                                                            props.instantPreferredDealer ===
                                                            dealer.DealerID.substring(
                                                                2
                                                            )
                                                        }
                                                        selectedModel={
                                                            props.selectedModel
                                                        }
                                                        instantPreferredDealer={
                                                            props.instantPreferredDealer
                                                        }
                                                        setInstantPreferredDealer={
                                                            props.setInstantPreferredDealer
                                                        }
                                                        updatePreferredDealer={
                                                            props.updatePreferredDealer
                                                        }
                                                        vin={props.vin}
                                                        scrollToTopOfDealerResultsList={
                                                            scrollToTopOfDealerResultsList
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    />
                                )}
                                {/* code below is for adding view more/ filter */}
                                {showViewMoreButton && (
                                    <div className="view-more-button__box">
                                        <button
                                            className="view-more-button"
                                            aria-label={
                                                preferredDealerContent.viewMoreButtonAriaLabel
                                            }
                                            onClick={() =>
                                                expandResultsByFive(
                                                    dealerResultsEu,
                                                    dealersByDistance
                                                )
                                            }
                                        >
                                            <span>
                                                {
                                                    preferredDealerContent.viewMoreButtonLabelText
                                                }
                                            </span>
                                            {appConfig.brand === 'lincoln' && (
                                                <div className="lincoln-underline-button-overlay"></div>
                                            )}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}
                {isSearching ? (
                    <ActivityIndicator className="full-height" />
                ) : null}
            </div>
        </>
    );
};

export default PreferredDealerComponent;
