import {
    EU_BING_BASE_URL,
    EU_BING_KEY,
    GOOGLE_MAP_SCRIPT,
    SERVICE_LOCATION_STEP_KEYS,
} from '@constants';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { EUDealer } from '@models/preferred-dealer';

const appConfig = new AppConfigurationService();

declare global {
    interface Window {
        processDealerResults: any;
    }
}

const MAX_BATCH_COUNT = 25;

export default class GoogleMapService {
    static loadScript = (url: string) => {
        if (
            url === 'google' &&
            (typeof window['google'] !== 'object' ||
                typeof window['google'].maps !== 'object')
        ) {
            url = GOOGLE_MAP_SCRIPT;
            const script: any = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            document.getElementsByTagName('head')[0].appendChild(script);
        } else if (url !== 'google') {
            const script: any = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    };

    static getReverseGeoCoding = (lat: number, lng: number, callback: any) => {
        const geocoder = new window['google'].maps.Geocoder();
        geocoder.geocode(
            {
                location: new window['google'].maps.LatLng(lat, lng),
                region:
                    appConfig.getAppConfiguration().countryCode === 'mothersite'
                        ? 'gb'
                        : appConfig.getAppConfiguration().countryCode,
            },
            function (results) {
                if (results && results[0]) {
                    callback(results[0].formatted_address);
                }
            }
        );
    };

    static getPlacePredictions = (
        input: string,
        callback: any,
        geoCountryCode: any,
        hasPostcodeSearch: boolean
    ) => {
        let locationPredictions = [];
        let placesCompleted = 0;
        const places: any = {};
        const ac = new window['google'].maps.places.AutocompleteService();
        let predictions: any[] = [];
        let postCodePredictions: any[] = [];
        const countryCodes: string[] = appConfig
            .getGeoCountryCode(geoCountryCode)
            .split(',');
        for (const element of countryCodes) {
            const placesPredictionsCallback = (
                results: google.maps.places.AutocompletePrediction[] | null,
                status: google.maps.places.PlacesServiceStatus
            ) => {
                locationPredictions = [];
                if (
                    status ==
                        window['google'].maps.places.PlacesServiceStatus.OK &&
                    results
                ) {
                    for (const element of results) {
                        locationPredictions.push(element);
                    }
                }
                places[element] = locationPredictions;
                placesCompleted++;

                if (placesCompleted === countryCodes.length) {
                    countryCodes.forEach((code) => {
                        if (hasPostcodeSearch) {
                            postCodePredictions = places[code];
                            postCodePredictions.forEach((item) => {
                                const hasPostalCodeItem =
                                    item?.types?.find(
                                        (type: string) =>
                                            type ===
                                            SERVICE_LOCATION_STEP_KEYS.SEARCH_PREDICTION_TYPE
                                    ) ===
                                    SERVICE_LOCATION_STEP_KEYS.SEARCH_PREDICTION_TYPE;
                                if (hasPostalCodeItem) {
                                    predictions.push(item);
                                }
                            });
                        } else {
                            predictions = predictions.concat(places[code]);
                        }
                    });
                    callback(predictions);
                }
            };
            ac.getPlacePredictions(
                {
                    input: input,
                    componentRestrictions: { country: element },
                    types: hasPostcodeSearch ? ['(regions)'] : ['geocode'],
                } as google.maps.places.AutocompletionRequest,
                placesPredictionsCallback
            );
        }
    };

    static getGeocodeLocation = (
        geoCountryCode: any,
        location: string,
        callback: any
    ) => {
        const geocoder = new window['google'].maps.Geocoder();
        const countryCodes: string[] = appConfig
            .getGeoCountryCode(geoCountryCode)
            .split(',');
        const locations = [];
        const geocodeResponse = (data: any) => {
            if (data) {
                let addLocation = false;
                for (let i = 0; i < data.length; i++) {
                    addLocation = false;
                    for (
                        let j = 0;
                        j < data[i].address_components.length;
                        j++
                    ) {
                        for (
                            let k = 0;
                            k < data[i].address_components[j].types.length;
                            k++
                        ) {
                            if (
                                data[i].address_components[j].types[k] ===
                                'country'
                            ) {
                                for (let l = 0; l < countryCodes.length; l++) {
                                    if (
                                        data[i].address_components[
                                            j
                                        ].short_name.toUpperCase() ===
                                        countryCodes[l].toUpperCase()
                                    ) {
                                        addLocation = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    if (addLocation) {
                        locations.push({
                            lat: data[i].geometry.location.lat(),
                            lng: data[i].geometry.location.lng(),
                            description: data[i].formatted_address,
                            types: data[i].types,
                            addressComponents: data[i].address_components,
                        });
                    }
                }
            }
            callback(locations);
        };
        const geocodeRequest: any = {
            address: location,
            region:
                appConfig.getAppConfiguration().countryCode === 'mothersite'
                    ? 'gb'
                    : appConfig.getAppConfiguration().countryCode,
        };
        // In the below code, added the language property to the geocoder options object,
        // which is then passed as the first argument to the geocoder.geocode() method.
        // This should force the Geocoding API to return the results in English, regardless of the user's location.
        // To fix the issue reported in Orange location in, France market.
        if (appConfig.getAppConfiguration().countryCode === 'fr') {
            geocodeRequest.language = 'en-US';
        }
        geocoder.geocode(geocodeRequest, geocodeResponse);
        return locations;
    };
    static calculateDistanceUsingGooglePlaces = async (
        origin: google.maps.LatLng,
        destination: google.maps.LatLng
    ): Promise<number | null> => {
        return new Promise((resolve, reject) => {
            const directionsService = new google.maps.DirectionsService();
            const request: google.maps.DirectionsRequest = {
                origin,
                destination,
                travelMode: google.maps.TravelMode.DRIVING,
            };

            directionsService.route(request, (response, status) => {
                if (
                    status === 'OK' &&
                    response?.routes?.[0]?.legs?.[0]?.distance?.text
                ) {
                    const distance = response.routes[0].legs[0].distance.value;
                    resolve(distance);
                } else {
                    console.error('Error calculating distance:', status);
                    reject(new Error('Failed to calculate distance'));
                }
            });
        });
    };
    static findDistanceBetweenAddresses = async (
        countryCode: string,
        address1: string,
        address2: string
    ): Promise<number | null> => {
        const getAddressGeocode = (
            address: string
        ): Promise<google.maps.LatLng> => {
            return new Promise<google.maps.LatLng>((resolve, reject) => {
                GoogleMapService.getGeocodeLocation(
                    countryCode,
                    address,
                    (geocode: any) => {
                        if (geocode && geocode.length > 0) {
                            const geocodeLatLng = new google.maps.LatLng(
                                geocode?.[0]?.lat,
                                geocode?.[0]?.lng
                            );
                            resolve(geocodeLatLng);
                        } else {
                            reject(
                                new Error(
                                    'Failed to get geocode for address: ' +
                                        address
                                )
                            );
                        }
                    }
                );
            });
        };
        try {
            const [address1Geocode, address2Geocode] = await Promise.all([
                getAddressGeocode(address1),
                getAddressGeocode(address2),
            ]);
            const distance =
                await GoogleMapService.calculateDistanceUsingGooglePlaces(
                    address1Geocode,
                    address2Geocode
                );
            return distance;
        } catch (error) {
            console.error('An error occurred:', error);
            return null;
        }
    };

    static getLocalisationConfig = (polygon: string) => {
        const polygons: { lng: string; lat: string }[] = [];
        polygon.split('%2C').forEach((item) => {
            const latLng = extractCoordinates(item);
            if (latLng) {
                polygons.push({ lng: latLng[0], lat: latLng[1] });
            }
        });
        return polygons;
    };

    static getBoundsString = (polygon: string) => {
        const countryBounds = GoogleMapService.getLocalisationConfig(polygon);
        let boundsString = '';
        let nLat = -91; //North is +X, so maximise it in the range
        let sLat = 91; //South is -X, so minimise it in the range

        let eLng = -181; //East is +Y, so maximise it in the range
        let wLng = 181; //West is -Y, so minimise it in the range
        if (countryBounds) {
            for (let i = 0; i < countryBounds.length; i++) {
                const x = countryBounds[i];
                const cb = { lat: 0, lng: 0 };
                cb.lat = Number(x.lat);
                cb.lng = Number(x.lng);
                if (x.lat !== '' || x.lng !== '') {
                    if (cb.lat > nLat) {
                        nLat = cb.lat;
                    }
                    if (cb.lat < sLat) {
                        sLat = cb.lat;
                    }
                    if (cb.lng > eLng) {
                        eLng = cb.lng;
                    }
                    if (cb.lng < wLng) {
                        wLng = cb.lng;
                    }
                }
            }
            boundsString =
                '(' + sLat + ',' + wLng + ',' + nLat + ',' + eLng + ')';
        }
        return boundsString;
    };

    //Bing call
    static searchDealersByDistance = (
        origin: any,
        radius: number,
        limit: number,
        callback: any,
        matchParameters: any,
        polygon: string
    ) => {
        let dealer: any = {};
        const dealers = [];

        window.processDealerResults = (data: any) => {
            for (let i = 0; i < data.d.results.length; i++) {
                dealer = data.d.results[i];
                dealer.location = {
                    lat: data.d.results[i].Latitude,
                    lng: data.d.results[i].Longitude,
                };
                dealer.distance = data.d.results[i].__Distance * 1000;
                dealers.push(dealer);
            }
            callback(dealers);
        };

        const boundsString = GoogleMapService.getBoundsString(polygon);
        const bingKey =
            'Al1EdZ_aW5T6XNlr-BJxCw1l4KaA0tmXFI_eTl1RITyYptWUS0qit_MprtcG7w2F';
        let url;
        const baseUrl =
                'https://spatial.virtualearth.net/REST/v1/data/1652026ff3b247cd9d1f4cc12b9a080b/FordEuropeDealers_Transition/Dealer' +
                '?',
            geoFilter = radius
                ? 'spatialFilter=nearby(' +
                  origin.lat +
                  ',' +
                  origin.lng +
                  ',' +
                  radius +
                  ')'
                : 'spatialFilter=bbox' + boundsString,
            select = '&$select=*,__Distance',
            matchArr = [];
        let filter = '&$filter=';
        const maxResults = '&$top=' + limit,
            format = '&$format=json',
            key = '&key=' + bingKey,
            jsonp = '&Jsonp=processDealerResults';

        encodeParameters(matchParameters, matchArr);

        if (matchArr.length) {
            filter += matchArr.join('%20And%20');
            url =
                baseUrl +
                geoFilter +
                select +
                filter +
                maxResults +
                format +
                key +
                jsonp;
        } else {
            url =
                baseUrl +
                geoFilter +
                select +
                maxResults +
                format +
                key +
                jsonp;
        }
        GoogleMapService.loadScript(url);

        return dealers;
    };

    static filterDealers = (
        dealers: any,
        containsParameters: any,
        maxResults: number
    ) => {
        if (!containsParameters) {
            return dealers;
        }

        const dealersFiltered = [];

        if (dealers.length > 0) {
            for (let i = 0; i < dealers.length; i++) {
                let allMatched = true;

                for (const key in containsParameters) {
                    if (containsParameters.hasOwnProperty.call(key)) {
                        const check = containsParameters[key];
                        const dealerString = dealers[i][key];

                        if (dealerString.indexOf(check) === -1) {
                            allMatched = false;
                        }
                    }
                }

                if (allMatched === true) {
                    dealersFiltered.push(dealers[i]);
                }
            }
        }

        let dealersReturned = [];

        if (dealersFiltered.length >= maxResults) {
            dealersReturned = dealersFiltered.slice(0, maxResults);
        } else {
            dealersReturned = dealersFiltered;
        }

        return dealersReturned;
    };

    //Bing call
    static searchDealersByProperties = (
        limit: any,
        callback: (dealers: EUDealer[]) => void,
        matchParameters: any
    ) => {
        const baseUrl = EU_BING_BASE_URL + '?';

        const select = '$select=*',
            matchArr = [];
        let filter = '&$filter=';
        const maxResults = '&$top=' + limit,
            format = '&$format=json',
            lineCount = '&$inlinecount=allpages',
            key = '&key=' + EU_BING_KEY,
            jsonp = '&Jsonp=processDealerResults';

        encodeParameters(matchParameters, matchArr);
        if (matchArr.length) {
            filter += matchArr.join('%20And%20');
        } else {
            throw new Error('Must specify at least one match parameter.');
        }

        let url =
            baseUrl +
            select +
            filter +
            maxResults +
            lineCount +
            format +
            key +
            jsonp;
        GoogleMapService.loadScript(url);
        const dealersCompleted: boolean[] = [];
        let dealerCount;
        const dealers: any[] = [];
        window.processDealerResults = (data: any) => {
            dealerCount = data.d.__count;
            let dealer;
            for (let i = 0; i < dealerCount / 250; i++) {
                dealersCompleted.push(false);
            }
            for (let i = 0; i < data.d.results.length; i++) {
                dealer = data.d.results[i];
                dealer.location = {
                    lat: data.d.results[i].Latitude,
                    lng: data.d.results[i].Longitude,
                };
                dealer.distance = data.d.results[i].__Distance * 1000;
                dealers.push(dealer);
            }

            if (dealerCount > limit) {
                let iterationCount = parseInt(limit, 10),
                    skip,
                    mResults;

                for (let outloop = 0; outloop < dealerCount / 250; outloop++) {
                    //rebuild the string
                    skip = '&$skip=' + iterationCount;
                    mResults = '&$top=' + 250;
                    url =
                        baseUrl +
                        select +
                        filter +
                        skip +
                        mResults +
                        format +
                        key +
                        jsonp;

                    //Increment the loop count.
                    iterationCount = iterationCount + 250;

                    //Re do it.
                    GoogleMapService.loadScript(url);

                    window.processDealerResults = function (data: any) {
                        let dealer;

                        for (let i = 0; i < data.d.results.length; i++) {
                            dealer = data.d.results[i];
                            dealer.location = {
                                lat: data.d.results[i].Latitude,
                                lng: data.d.results[i].Longitude,
                            };
                            dealer.distance =
                                data.d.results[i].__Distance * 1000;
                            dealers.push(dealer);
                        }
                    };
                }
                return null;
            } else {
                callback(dealers);
                return dealers;
            }
        };
    };

    static getDealerDistance = (
        origin: any,
        limit: any,
        dealers: string | any[],
        callback: (arg0: any) => void
    ) => {
        const service = new window['google'].maps.DistanceMatrixService();
        const destinationLatLngs: any[] = [];
        const distanceMatrixDealers: any[] = [];

        //Create parallel array of lat lngs to pass into distance matrix
        for (let i = 0; i < dealers.length; i++) {
            destinationLatLngs.push(
                new window['google'].maps.LatLng(
                    dealers[i].location.lat,
                    dealers[i].location.lng
                )
            );
        }

        //(Dealers chopped into) Chunks of 25, rounded up.
        //This is the have-I-finished array for AJAX calls.
        const distanceResponses = new Array(
            Math.ceil(destinationLatLngs.length / MAX_BATCH_COUNT)
        );

        if (dealers.length) {
            const createDistanceCallback = (index: number) => {
                return function (distances: any) {
                    const count = destinationLatLngs.slice(
                        index * MAX_BATCH_COUNT,
                        index * MAX_BATCH_COUNT + MAX_BATCH_COUNT
                    ).length;

                    if (distances) {
                        for (
                            let j = 0;
                            j < distances.rows[0].elements.length;
                            j++
                        ) {
                            if (distances.rows[0].elements[j].status === 'OK') {
                                dealers[index * MAX_BATCH_COUNT + j].distance =
                                    distances.rows[0].elements[
                                        j
                                    ].distance.value;
                                dealers[
                                    index * MAX_BATCH_COUNT + j
                                ].drivingTime =
                                    distances.rows[0].elements[
                                        j
                                    ].duration.value;
                            } else if (
                                dealers[index * MAX_BATCH_COUNT + j].__Distance
                            ) {
                                //Google's getDistanceMatrix, not providing distance, fall back to BING distance
                                dealers[index * MAX_BATCH_COUNT + j].distance =
                                    dealers[index * MAX_BATCH_COUNT + j]
                                        .__Distance * 1000;
                            } else {
                                //Error handling for no distance.
                                dealers[
                                    index * MAX_BATCH_COUNT + j
                                ].distanceError =
                                    distances.rows[0].elements[j].status;
                                dealers[index * MAX_BATCH_COUNT + j].distance =
                                    null;
                            }
                            distanceMatrixDealers.push(
                                dealers[index * MAX_BATCH_COUNT + j]
                            );
                        }
                    } else {
                        //Error is no distances array returned
                        for (let j = 0; j < count; j++) {
                            dealers[index * MAX_BATCH_COUNT + j].distanceError =
                                'DRIVING DISTANCE UNAVAILABLE';
                            dealers[index * MAX_BATCH_COUNT + j].distance =
                                null;
                            distanceMatrixDealers.push(
                                dealers[index * MAX_BATCH_COUNT + j]
                            );
                        }
                    }

                    //This chunk of dealers has come back.
                    distanceResponses[index] = true;
                    let responsesReturned = true;

                    //If any of the chunks haven't returned, we fail.
                    for (let k = 0; k < distanceResponses.length; k++) {
                        if (!distanceResponses[k]) {
                            responsesReturned = false;
                        }
                    }
                    if (responsesReturned) {
                        //The dealers array becomes our array of dealers that went through the distance matrix.
                        //This seems the dealers are in the right order regardless of function-call return order.
                        dealers = [...distanceMatrixDealers].sort(function (
                            a,
                            b
                        ) {
                            if (
                                typeof a.distance === 'number' &&
                                typeof b.distance === 'number'
                            ) {
                                return a.distance - b.distance;
                            } else {
                                if (
                                    typeof a.distance !== 'number' &&
                                    typeof b.distance !== 'number'
                                ) {
                                    return a.directDistance - b.directDistance;
                                }
                                if (typeof a.distance === 'number') {
                                    return -1;
                                } else {
                                    return 1;
                                }
                            }
                        });
                        callback(dealers.slice(0, limit));
                    }
                };
            };
            for (
                let i = 0;
                i < destinationLatLngs.length / MAX_BATCH_COUNT;
                i++
            ) {
                service.getDistanceMatrix(
                    {
                        origins: [origin],
                        destinations: destinationLatLngs.slice(
                            i * MAX_BATCH_COUNT,
                            i * MAX_BATCH_COUNT + MAX_BATCH_COUNT
                        ),
                        travelMode: window['google'].maps.TravelMode.DRIVING,
                    },
                    createDistanceCallback(i)
                );
            }
        } else {
            callback(dealers);
        }
    };
}
export const encodeParameters = (matchParameters: any, matchArr: any) => {
    if (matchParameters && matchParameters.OR_CLAUSES) {
        for (let i = 0; i < matchParameters.OR_CLAUSES.length; i++) {
            const orArr = [];
            for (const orKey in matchParameters.OR_CLAUSES[i]) {
                if (orKey.indexOf('!') === 0) {
                    orArr.push(
                        orKey.slice(1) +
                            '%20Ne%20%27' +
                            matchParameters.OR_CLAUSES[i][orKey] +
                            '%27'
                    );
                } else {
                    orArr.push(
                        orKey +
                            '%20Eq%20%27' +
                            matchParameters.OR_CLAUSES[i][orKey] +
                            '%27'
                    );
                }
            }
            matchArr.push('(' + orArr.join('%20OR%20') + ')');
        }
        delete matchParameters.OR_CLAUSES;
    }

    if (matchParameters) {
        for (const k in matchParameters) {
            if (k.indexOf('!') === 0) {
                matchArr.push(
                    k.slice(1) + '%20Ne%20%27' + matchParameters[k] + '%27'
                );
            } else {
                matchArr.push(k + '%20Eq%20%27' + matchParameters[k] + '%27');
            }
        }
    }
};

export const extractCoordinates = (item: string) => {
    const matches: string[] = [];
    let number = '';
    const matchNumber = (num: string) => {
        if (num && num !== '-' && num !== '.' && num !== '-.') {
            matches.push(num);
        }
    };
    for (const char of item) {
        if (
            (char >= '0' && char <= '9') ||
            (char === '-' && number === '') ||
            (char === '.' && number.indexOf('.') === -1)
        ) {
            number += char;
        } else {
            matchNumber(number);
            number = '';
        }
    }
    matchNumber(number);
    return matches;
};
