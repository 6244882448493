import React from 'react';
import LincolnChevronDark from '@assets/arrow-forward-graphite.svg';
import LincolnChevronLight from '@assets/arrow-forward-white.svg';
import { FdsChevron } from '../../chevron/fds-chevron';
import './link-button.scss';

export type ButtonType =
    | ''
    | 'white'
    | 'white-left'
    | 'blue'
    | 'transparent'
    | 'blue-filled'
    | 'transparent-no-chevron'
    | 'lincoln-chevron-light'
    | 'lincoln-chevron-dark'
    | 'outline';

interface Props {
    class?: string;
    dataTestId?: string;
    label: string;
    ariaLabel?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    nextControlRef?: React.RefObject<HTMLButtonElement>;
    ref?: React.RefObject<HTMLButtonElement>;
    buttonType?: ButtonType;
    id?: string;
    ariaExpanded?: boolean;
    ariaControls?: string;
    ariaHidden?: boolean;
    tabIndex?: number;
    isFromMarketingOptions?: boolean;
    isEditButton?: boolean;
}

export const LinkButton = React.forwardRef(
    (props: Props, ref: React.Ref<HTMLButtonElement>) => {
        const chooseChevron = () => {
            switch (props.buttonType) {
                case 'white-left':
                    return <FdsChevron type="unfilled" direction="left" />;
                case 'white':
                case 'transparent':
                    return <FdsChevron type="unfilled" direction="right" />;
                case 'transparent-no-chevron':
                    return <></>;
                case 'outline':
                    return <></>;
                case 'blue':
                    return <FdsChevron type="unfilled" direction="right" />;
                case 'blue-filled':
                    return <FdsChevron type="filled" direction="right" />;
                case 'lincoln-chevron-dark':
                    return (
                        <img
                            className="align-chevron"
                            src={LincolnChevronDark}
                            alt={'Arrow right'}
                        />
                    );
                case 'lincoln-chevron-light':
                    return (
                        <img
                            className="align-chevron"
                            src={LincolnChevronLight}
                            alt={'Arrow right'}
                        />
                    );
                case '':
                    return <></>;
            }
        };

        const focusNextControl = (
            event: React.KeyboardEvent<HTMLButtonElement>
        ) => {
            if (event.key == 'Tab') {
                if (
                    props.nextControlRef &&
                    props.nextControlRef.current !== null
                ) {
                    event.preventDefault();
                    props.nextControlRef.current.focus();
                }
            }
        };
        const hasNoFmcClass =
            props.isFromMarketingOptions || props.isEditButton;

        return (
            <button
                ref={ref}
                className={`${hasNoFmcClass ? props.class : 'fmc-text-button'}`}
                onClick={props.onClick}
                onKeyDown={focusNextControl}
                data-testid={props.dataTestId}
                aria-label={props.ariaLabel}
                aria-expanded={props.ariaExpanded}
                aria-controls={props.ariaControls}
                id={props.id}
                aria-hidden={props.ariaHidden}
                tabIndex={props.tabIndex}
            >
                <span>{props.label}</span>
                {chooseChevron()}
            </button>
        );
    }
);
LinkButton.displayName = 'LinkButton';
