import React, {
    Dispatch,
    KeyboardEvent,
    SetStateAction,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { EUDealer } from '@models/preferred-dealer';
import { useWindowSize, WindowSize } from '@hooks/use-window-size';
import {
    SwipePoint,
    useSwipe,
} from '@sections/account-portal/components/vehicle-carousel/hooks/use-swipe';
import { KEYBOARD_KEYS } from '@constants';
import MobileCarouselIndicators from '@views/vehicle-order-tracking-status-view/components/vehicle-image-carousel/mobile-carousel-indicators/mobile-carousel-indicators';
import './eu-mobile-carousel.scss';

interface EuMobileCarouselProps {
    cards: JSX.Element[];
    activeDealer: EUDealer;
    setActiveDealer: Dispatch<SetStateAction<EUDealer>>;
    dealers: EUDealer[];
}

const EuMobileCarousel = (props: EuMobileCarouselProps): JSX.Element => {
    const dealerCardRef = useRef<HTMLLIElement>(null);
    const size: WindowSize = useWindowSize();
    const [cardsPositionX, setCardsPositionX] = useState<number>(0);
    const [swipeOffsetX, setSwipeOffsetX] = useState<number>(0);
    const [swipeStartX, setSwipeStartX] = useState<number | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(size.width < 768);
    const [workingIndex, setWorkingIndex] = useState<number>(0);

    const handleSwipeStart = useCallback(
        (swipePoint: SwipePoint): void => {
            setSwipeStartX(swipePoint.x);
        },
        [setSwipeStartX]
    );

    const handleSwipeMove = useCallback(
        (swipePoint: SwipePoint) => {
            if (swipeStartX) {
                setSwipeOffsetX(swipePoint.x - swipeStartX);
            }
        },
        [swipeStartX, setSwipeOffsetX]
    );

    const handleSwipeEnd = useCallback(() => {
        let closestCardIndex = 0;
        let closestCardX = 0;
        for (
            let cardIndex = 0;
            cardIndex <= props.cards.length - 1;
            cardIndex++
        ) {
            const cardPosX =
                cardIndex *
                (dealerCardRef.current?.getBoundingClientRect().width || 0);
            if (
                Math.abs(cardsPositionX + cardPosX + swipeOffsetX) <
                Math.abs(cardsPositionX + closestCardX + swipeOffsetX)
            ) {
                closestCardIndex = cardIndex;
                closestCardX = cardPosX;
            }
        }
        setWorkingIndex(closestCardIndex);
        setSwipeStartX(null);
        setSwipeOffsetX(0);
    }, [
        dealerCardRef,
        swipeOffsetX,
        setSwipeStartX,
        setWorkingIndex,
        cardsPositionX,
    ]);

    const carouselTransition = {
        left: cardsPositionX + swipeOffsetX,
        transition: 'left 400ms ease',
        willChange: 'left',
    };

    const carouselContainerRef = useSwipe(
        isMobile,
        handleSwipeStart,
        handleSwipeMove,
        handleSwipeEnd
    );

    const moveToPreviousDealer = (): void => {
        if (workingIndex > 0) {
            setWorkingIndex(workingIndex - 1);
        }
    };

    const moveToNextDealer = (): void => {
        if (workingIndex < props.cards.length - 1) {
            setWorkingIndex(workingIndex + 1);
        }
    };

    const updateCardPositions = useCallback(() => {
        setCardsPositionX(
            -workingIndex *
                (dealerCardRef.current?.getBoundingClientRect().width + 16)
        );
    }, [dealerCardRef, workingIndex, setCardsPositionX]);

    const tabNavigation = (e: KeyboardEvent): void => {
        if (e.shiftKey && e.key === KEYBOARD_KEYS.TAB) {
            moveToPreviousDealer();
        } else if (e.key === KEYBOARD_KEYS.TAB) {
            moveToNextDealer();
        }
    };

    useLayoutEffect(() => {
        updateCardPositions();
    }, [workingIndex, isMobile, updateCardPositions]);

    useEffect(() => {
        props.setActiveDealer(props.dealers[workingIndex]);
    }, [workingIndex]);

    useEffect(() => {
        if (size.width < 992 && !isMobile) {
            setIsMobile(true);
        } else if (size.width >= 992 && isMobile) {
            setIsMobile(false);
        }
    }, [size.width]);

    return (
        <>
            <div className="fmc-carousel-container">
                <div
                    ref={carouselContainerRef}
                    className="eu-mobile-carousel js-fmc-carousel-container"
                    data-testid="eu-mobile-carousel"
                >
                    <ul
                        className="fmc-carousel fmc-cards carousel__dealer-list"
                        style={
                            dealerCardRef
                                ? carouselTransition
                                : { left: cardsPositionX }
                        }
                    >
                        {props.cards.map((dealer, index) => {
                            return (
                                <li
                                    id={`slide__${index}`}
                                    className="carousel__slide--dealer"
                                    key={`carousel__image-list--item-${index}`}
                                    ref={dealerCardRef}
                                    onKeyDown={tabNavigation}
                                >
                                    {dealer}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <MobileCarouselIndicators
                workingIndex={workingIndex}
                items={props.cards.map((card) => card.props.dealerName)}
            />
        </>
    );
};

export default EuMobileCarousel;
