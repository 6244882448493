import React, { useContext, useEffect, useState } from 'react';
import { usePreferredDealerContent } from '@views/preferred-dealer-view/hooks/use-preferred-dealer-content';
import { useReturnButtonContent } from '@sections/return-button/hook/use-return-button-content';
import PreferredDealerComponent from '@views/preferred-dealer-view/components/preferred-dealer-component/preferred-dealer-component';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { findPathByAlias } from '@routes/routesList';
import { ScrollUtil } from '@utils/scroll-to-top-util/scroll-to-top-util';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import HttpService from '@services/http-service/http-service';
import PreferredDealerService from '@services/preferred-dealer-service/preferred-dealer-service';
import ProfileService from '@services/profile-service/profile-service';
import BingService from '@services/bing-service/bing-service';
import { CacheService } from '@services/cache-service/cache-service';
import { VehicleDetail } from '@models/profile-with-vehicles';
import { SearchBar } from '@/components/sections';
import { useWindowSize } from '@hooks/use-window-size';
import {
    EU_PREFERRED_DEALER_ID_PREFIXES,
    VEHICLE_CARD_STORAGE_KEY,
} from '@constants';
import ServerContext from '@contexts/serverContext';
import { useAnalytics } from '@hooks/use-analytics';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import GoogleMapService from '@services/google-map-service/google-map-service';

import './preferred-dealer-view.scss';
import ReturnButton from '@sections/return-button/return-button';

export interface PreferredDealerItem {
    vin: string;
    model: string;
    preferredDealerCode: string;
    modelYear?: string;
    modelName?: string;
    preferredDealer?: string;
}

const PreferredDealerView = () => {
    const navigate = useNavigate();
    const { setNotificationContext } = useNotificationContext();
    const [fireAnalytics] = useAnalytics();
    const httpService = HttpService;
    const appConfig = new AppConfigurationService();
    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';
    const bingService = new BingService(httpService);
    const [profileVehicles, setProfileVehicles] =
        useState<VehicleDetail[]>(null);
    const [vinVehicleData, setVinVehicleData] = useState<any>(null);
    const size = useWindowSize();
    const TABLET_MOBILE_BREAKPOINT = 768;
    const isDesktop = size.width > TABLET_MOBILE_BREAKPOINT;
    const returnButtonContent = useReturnButtonContent();
    const preferredDealerContent = usePreferredDealerContent();
    const authenticationService = new AuthenticationService();
    const profileService = new ProfileService();
    const [showPreferredDealerComponent, setShowPreferredDealerComponent] =
        useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [preferredDealerData, setPreferredDealerData] =
        useState<PreferredDealerItem>(null);
    const [instantPreferredDealer, setInstantPreferredDealer] =
        useState<string>(null);
    const [hasCheckedSessionStorageForVin, setHasCheckedSessionStorageForVin] =
        useState<boolean>(false);
    const dashboardPathWithPreferredDealerParam: string =
        findPathByAlias('AccountDashboardView') + '?preferredDealer=true';
    const [defaultDealerLocation, setDefaultDealerLocation] =
        useState<string>(null);
    const isWebview =
        new URLSearchParams(useLocation().search).get('webview') || undefined;
    const context = useContext(ServerContext);
    const webviewData = context.webviewData;

    const searchEuByDealerCode = (dealerCode: string) => {
        GoogleMapService.searchDealersByProperties(
            1,
            (response) => {
                response[0]
                    ? setDefaultDealerLocation(response[0]?.Locality)
                    : setDefaultDealerLocation('');
            },
            {
                DealerID: dealerCode,
            }
        );
    };

    const updatePreferredDealer = async ({
        vin,
        preferredDealerCode,
    }: PreferredDealerItem) => {
        const httpService = HttpService;
        const preferredDealerService = new PreferredDealerService(httpService);
        setInstantPreferredDealer(preferredDealerCode);

        await preferredDealerService.updatePreferredDealer(
            vin,
            preferredDealerCode
        );
        sessionStorage.setItem(
            VEHICLE_CARD_STORAGE_KEY,
            JSON.stringify({
                vin,
            })
        );
        if (isEu) {
            searchEuByDealerCode(preferredDealerCode);
        } else {
            bingService
                .getDealerInfoById(preferredDealerCode)
                .then((response) => {
                    if (response[0].state) {
                        setDefaultDealerLocation(
                            `${response[0].city}, ${response[0].state}`
                        );
                    }
                });
        }

        new CacheService().evictProfileLiteCache();
        new CacheService().evictBingServiceCache();
    };

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
        authenticationService.onIsAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                const selectedVinFromStorage = JSON.parse(
                    sessionStorage.getItem(VEHICLE_CARD_STORAGE_KEY)
                );
                if (!selectedVinFromStorage) {
                    navigate(dashboardPathWithPreferredDealerParam);
                }
                profileService
                    .requestLite()
                    .then((profile) => {
                        if (profile) {
                            // check for vehicle in user's garage
                            setVinVehicleData(
                                profile.vehicles.find((vehicleData) => {
                                    if (
                                        vehicleData.vin ===
                                        selectedVinFromStorage.vin
                                    ) {
                                        return vehicleData;
                                    }
                                })
                            );
                            setProfileVehicles(profile.vehicles);
                            setHasCheckedSessionStorageForVin(true);
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setShowPreferredDealerComponent(true);
                    });
            } else if (isWebview && webviewData.vin) {
                profileService
                    .requestLite()
                    .then((profile) => {
                        if (profile) {
                            // check for vehicle in user's garage
                            setVinVehicleData(
                                profile.vehicles.find((vehicleData) => {
                                    if (vehicleData.vin === webviewData.vin) {
                                        return vehicleData;
                                    }
                                })
                            );
                            setProfileVehicles(profile.vehicles);
                            setInstantPreferredDealer(
                                vinVehicleData?.preferredDealer
                            );
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setShowPreferredDealerComponent(true);
                    });
            } else {
                authenticationService.updateState(
                    findPathByAlias('PreferredDealerView')
                );
                authenticationService.login();
            }
        });

        new ScrollUtil().scrollPageToTop();
    }, []);

    useEffect(() => {
        if (hasCheckedSessionStorageForVin && profileVehicles) {
            const userHasVehicle = !!profileVehicles.find(
                (vehicle: VehicleDetail) =>
                    vehicle.vin === preferredDealerData?.vin
            );
            if (!userHasVehicle) {
                navigate(dashboardPathWithPreferredDealerParam);
            }
        }
    }, [hasCheckedSessionStorageForVin, profileVehicles, preferredDealerData]);

    useEffect(() => {
        if (
            preferredDealerData?.vin &&
            (preferredDealerData?.preferredDealerCode ||
                preferredDealerData.preferredDealer)
        ) {
            if (isEu) {
                searchEuByDealerCode(
                    EU_PREFERRED_DEALER_ID_PREFIXES.find(
                        (country) =>
                            country.country === appConfig.currentCountryCode
                    ).prefix +
                        (preferredDealerData.preferredDealer ||
                            preferredDealerData?.preferredDealerCode)
                );
            } else {
                bingService
                    .getDealerInfoById(
                        preferredDealerData.preferredDealerCode ||
                            preferredDealerData.preferredDealer
                    )
                    .then((response) => {
                        if (response[0]?.state && !isEu) {
                            setDefaultDealerLocation(
                                `${response[0].city}, ${response[0].state}`
                            );
                        }
                    });
            }
        } else {
            setDefaultDealerLocation('');
        }
    }, [preferredDealerData]);

    useEffect(() => {
        if (vinVehicleData) {
            setPreferredDealerData(vinVehicleData);
            setInstantPreferredDealer(vinVehicleData?.preferredDealer);
        }
    }, [vinVehicleData]);

    const vehicleYearAndModel: string = preferredDealerData
        ? preferredDealerData.modelYear + ' ' + preferredDealerData.modelName
        : null;

    return (
        <>
            <div
                className="preferred-dealer__view"
                data-testid="preferred-dealer-view"
            >
                {returnButtonContent && (
                    <ReturnButton
                        returnButtonContent={returnButtonContent}
                        onClick={() =>
                            fireAnalytics(
                                'backButtonPreferredDealerOnclickEvent'
                            )
                        }
                        fromPreferredDealerPage
                    ></ReturnButton>
                )}

                {showPreferredDealerComponent &&
                preferredDealerContent &&
                !isLoading &&
                defaultDealerLocation !== null ? (
                    <PreferredDealerComponent
                        preferredDealerContent={preferredDealerContent}
                        vin={vinVehicleData?.vin || webviewData?.vin}
                        selectedModel={
                            vinVehicleData?.model || vehicleYearAndModel
                        }
                        selectedPreferredDealer={
                            vinVehicleData?.preferredDealerCode ||
                            vinVehicleData?.preferredDealer
                        }
                        updatePreferredDealer={updatePreferredDealer}
                        instantPreferredDealer={
                            instantPreferredDealer ||
                            vinVehicleData?.preferredDealerCode ||
                            vinVehicleData?.preferredDealer
                        }
                        setInstantPreferredDealer={setInstantPreferredDealer}
                        defaultDealerLocation={
                            defaultDealerLocation ? defaultDealerLocation : null
                        }
                        isMobile={!isDesktop}
                    />
                ) : null}

                {isLoading && <ActivityIndicator className="full-height" />}
            </div>
            {isDesktop && (
                <hr className="hr-line mB0 preferred-dealer__horizontal-rule" />
            )}
            <div
                className="preferred-dealer__self-help-wrapper"
                data-testid="search-bar-in-preferred-dealer-view"
            >
                <SearchBar />
            </div>
        </>
    );
};

export default PreferredDealerView;
